@import '../../theme/colors.scss';

.filters-container {
  color: $emperorGray;
  label {
    font-size: 12px;
  }
  .filters-title {
    font-weight: normal;
    margin-bottom: 50px;
  }
  .generic-filter {
    align-items: center;
    display: flex;
    flex-direction: row;
    input#datetime-local {
      width: 175px;
      font-size: 12px;
      height: 20px !important;
    }
    max-width: 290px;
  }
  .MuiInput-underline:after {
    left: 0;
    right: 0;
    bottom: 0;
    content: '';
    transition: none;
    border-bottom: 2px solid transparent;
    pointer-events: none;
  }
  .MuiInput-underline:before {
    left: 0;
    right: 0;
    bottom: 0;
    content: '\00a0';
    position: absolute;
    pointer-events: none;
    transition: none;
    border-bottom: none;
  }
  .submit-area {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 50px;
    .reset-button {
      display: flex;
      align-items: center;
      color: $havelockBlue;
      cursor: pointer;
      margin-right: 25px;
      .reset-icon {
        margin-right: 5px;
      }
    }
    .submit-button {
      color: white;
    }
  }

  &-right-pane {
    justify-content: flex-start;

    @media screen and (min-width: 1280px) {
      justify-content: flex-end;
    }
  }
}
