$concreteGray: #f2f2f2;
$mercuryGray: #e3e3e3;
$mineShaftGray: #3b3b3b;
$havelockBlue: #669cdb;
$silver: #c1c1c1;
$altoGray: #dfdfdf;
$emperorGray: #4f4f4f;
$persianGreen: #02aa9e;
$menuGreen: #079e92;
$shadyGray: rgba(0, 0, 0, 0.25);
$lightShadyGray: rgba(102, 156, 219, 0.5);
$mediumShadyGray: rgba(59, 59, 59, 0.4);
$dangerRed: #e5245e;
