@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed&display=swap');
@import './theme/colors.scss';

@font-face {
  font-family: 'LynkcoType-Regular';
  font-style: normal;
  font-weight: 400;
  src: url('../src/assets/fonts/LynkcoType-Regular.woff');
  src: local('LynkcoType-Regular'), local('LynkcoType-Regular'),
    url('../src/assets/fonts/LynkcoType-Regular.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../src/assets/fonts/LynkcoType-Regular.woff') format('woff'),
    /* Modern Browsers */ url('../src/assets/fonts/LynkcoType-Regular.ttf') format('truetype'); /* Safari, Android, iOS */
}

@font-face {
  font-family: 'LynkcoType-Medium';
  font-style: normal;
  font-weight: 400;
  src: url('../src/assets/fonts/LynkcoType-Medium.woff');
  src: local('LynkcoType-Medium'), local('LynkcoType-Medium'),
    url('../src/assets/fonts/LynkcoType-Medium.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../src/assets/fonts/LynkcoType-Medium.woff') format('woff'),
    /* Modern Browsers */ url('../src/assets/fonts/LynkcoType-Medium.ttf') format('truetype'); /* Safari, Android, iOS */
}

@font-face {
  font-family: 'LynkcoType-Light';
  font-style: normal;
  font-weight: 400;
  src: url('../src/assets/fonts/LynkcoType-Light.woff');
  src: local('LynkcoType-Light'), local('LynkcoType-Light'),
    url('../src/assets/fonts/LynkcoType-Light.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../src/assets/fonts/LynkcoType-Light.woff') format('woff'),
    /* Modern Browsers */ url('../src/assets/fonts/LynkcoType-Light.ttf') format('truetype'); /* Safari, Android, iOS */
}

@font-face {
  font-family: 'LynkcoType-Bold';
  font-style: normal;
  font-weight: 400;
  src: url('../src/assets/fonts/LynkcoType-Bold.woff');
  src: local('LynkcoType-Bold'), local('LynkcoType-Bold'),
    url('../src/assets/fonts/LynkcoType-Bold.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../src/assets/fonts/LynkcoType-Bold.woff') format('woff'),
    /* Modern Browsers */ url('../src/assets/fonts/LynkcoType-Bold.ttf') format('truetype'); /* Safari, Android, iOS */
}

body {
  margin: 0;
  padding: 0;
  font-family: 'LynkcoType-Regular', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

* {
  margin: 0;
  padding: 0;
}

:root {
  --primary-text-color: #333;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.input-text {
  padding: 6px;
  border: 1px solid #e3e3e3;
  margin-left: 20px;
  height: 20px !important;
}

.padding-vertical-5 {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.MuiSelect-root.MuiSelect-select.MuiInputBase-input.MuiInput-input {
  min-width: 150px;
  height: 20px !important;
}
.MuiInputBase-root.MuiInput-root.MuiInput-underline {
  border: 1px solid $mercuryGray;
  padding-left: 10px;
  margin-left: 15px;
}
.MuiSelect-select.MuiSelect-select {
  border-bottom: none;
  // color: rgba(0, 0, 0, 0.25);
  font-size: 12px;
}
.MuiFormControl-root.MuiTextField-root {
  width: 200px;
  margin-left: auto;
}

.MuiInputBase-root.MuiInput-root.MuiInput-underline.Mui-focused.Mui-focused {
  select {
    background-color: transparent;
    border-bottom-color: transparent;
  }
}

.MuiRadio-colorSecondary.Mui-checked {
  color: $persianGreen !important;
}

.error {
  .MuiSelect-root.MuiSelect-select.MuiInputBase-input.MuiInput-input {
    min-width: 150px;
  }
  .MuiInputBase-root.MuiInput-root.MuiInput-underline {
    border: 1px solid red;
    color: red;
  }
  label,
  legend {
    color: red !important;
  }
  .input-text {
    border: 1px solid red !important;
  }
  .textarea {
    border: 1px solid red !important;
  }
}
.error-attachment {
  color: red !important;
  span {
    color: red !important;
  }
  .dzu-dropzone {
    border: 1px dashed red !important;
  }
}

.error-container {
  flex-direction: column;
  .error-msg {
    font-size: 12px;
    color: red;
  }
}

.textarea {
  border: 1px solid $mercuryGray;
  padding: 5px;
  width: 100%;
}

.text-center {
  text-align: center;
}
