.loadingScreen-container {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(20, 20, 20, 0.7);
  z-index: 99;
  .loader {
    top: 50%;
    left: 50%;
    position: fixed;
    z-index: 999;
    transform: translate(-50%, -50%);
  }
}
