@import '../../theme/colors.scss';

.dzu-dropzone {
  width: 100%;
  height: 98%;
  margin: 0;
  border: 1px dashed $shadyGray;
  padding: 5px 0;
  overflow-x: hidden;
  overflow-y: hidden;
  .upload-image-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    justify-content: center;
    cursor: pointer;
  }
  .dzu-previewContainer {
    flex-direction: column;
    padding: 0;
    height: 100%;
    justify-content: space-evenly;
    img {
      max-height: 80%;
    }
    .dzu-previewStatusContainer {
      width: 80%;
    }
    .dzu-previewButton {
      margin: 0 auto;
    }
  }
  .dzu-previewContainer {
    color: red;
    text-align: center;
    border-bottom: none;
    margin-bottom: 5px;
  }
  .dzu-submitButtonContainer {
    margin: 0;
    button.dzu-submitButton {
      padding: 0 14px;
      min-height: 32px;
      background-color: $havelockBlue;
      border: none;
      border-radius: 4px;
      font-family: 'Arial', sans-serif;
      font-size: 14px;
      font-weight: norma;
      color: #fff;
      cursor: pointer;
    }
  }
}
